var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"mt-10 relative"},[_c('a-form-item',{staticClass:"travel-input-outer"},[_c('label',{staticClass:"filled"},[_vm._v("Valor a ser retirado (R$)")]),_vm._v(" "),_c('a-input',{directives:[{name:"currency",rawName:"v-currency",value:({
          currency: {
            prefix: '',
            suffix: '',
          },
          distractionFree: false,
          valueAsInteger: false,
          precision: 2,
          autoDecimalMode: true,
          allowNegative: false,
        }),expression:"{\n          currency: {\n            prefix: '',\n            suffix: '',\n          },\n          distractionFree: false,\n          valueAsInteger: false,\n          precision: 2,\n          autoDecimalMode: true,\n          allowNegative: false,\n        }"}],staticClass:"travel-input",attrs:{"placeholder":"'Digite o valor"},model:{value:(_vm.creditLetterEmission.value),callback:function ($$v) {_vm.$set(_vm.creditLetterEmission, "value", $$v)},expression:"creditLetterEmission.value"}},[_c('template',{slot:"prefix"},[_vm._v(" R$ ")])],2)],1)],1),_vm._v(" "),_c('div',{staticClass:"mt-10 relative"},[_c('a-form-item',{staticClass:"travel-input-outer"},[_c('label',{staticClass:"filled"},[_vm._v("ID Voyage (opcional)")]),_vm._v(" "),_c('a-input',{staticClass:"travel-input",attrs:{"placeholder":"'Digite"},model:{value:(_vm.creditLetterEmission.idVoyage),callback:function ($$v) {_vm.$set(_vm.creditLetterEmission, "idVoyage", $$v)},expression:"creditLetterEmission.idVoyage"}})],1)],1),_vm._v(" "),_c('div',{staticClass:"mt-10 relative"},[_c('a-form-item',{staticClass:"travel-input-outer"},[_c('label',{staticClass:"filled"},[_vm._v("ID Contrato (opcional)")]),_vm._v(" "),_c('a-input',{staticClass:"travel-input",attrs:{"placeholder":"'Digite"},model:{value:(_vm.creditLetterEmission.contractId),callback:function ($$v) {_vm.$set(_vm.creditLetterEmission, "contractId", $$v)},expression:"creditLetterEmission.contractId"}})],1)],1),_vm._v(" "),_c('div',{staticClass:"mt-30 a-center"},[_c('a-popconfirm',{attrs:{"title":'Tem certeza que deseja retirar R$ ' +
        _vm.creditLetterEmission.value +
        '?',"ok-text":"Sim","cancel-text":"Não","disabled":_vm.creditLetterEmission.value != undefined ? false : true},on:{"confirm":_vm.confirmCreditLetterEmission}},[_c('a-button',{attrs:{"loading":_vm.creditLetterEmission.loading,"disabled":_vm.creditLetterEmission.value != undefined ? false : true,"type":"primary"}},[_vm._v("\n        CONFIRMAR RETIRADA\n      ")])],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }