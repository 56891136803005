import { format, parse } from "date-fns";

export default {
  filters: {
    formatPriceBR(price) {
      return `${Number(parseFloat(price).toFixed(2)).toLocaleString(
        "pt-BR",
        {}
      )}`;
    },
    formatPrice(price) {
      return `${Number(parseFloat(price).toFixed(2)).toLocaleString()}`;
    },
    formatCurrency(currency) {
      if (currency === "brl") currency = "R$";
      if (currency === "usd") currency = "U$";
      if (currency === "eur") currency = "€";
      return currency;
    },
    formatDateTime(date) {
      let theDate = "";

      if (date)
        theDate = format(
          parse(date, "yyyy-MM-dd HH:mm:ss", new Date()),
          "dd/MM/yyyy HH:mm"
        );

      return theDate;
    },

    formatDate(date) {
      let theDate = "";

      if (date)
        theDate = format(parse(date, "yyyy-MM-dd", new Date()), "dd/MM/yyyy");

      return theDate;
    },
    formatContractStatus(status) {
      if (status === "new") status = "Novo";
      if (status === "concluded") status = "Concluído";
      if (status === "canceled") status = "Cancelado";
      if (status === "edit") status = "Edição";
      return status;
    },
  },
  methods: {
    formatPrice(price) {
      return `${Number(parseFloat(price).toFixed(2)).toLocaleString()}`;
    },
    formatDate(date) {
      let theDate = "";

      if (date)
        theDate = format(parse(date, "yyyy-MM-dd", new Date()), "dd/MM/yyyy");

      return theDate;
    },
    formatDateTime(date) {
      let theDate = "";

      if (date)
        theDate = format(
          parse(date, "yyyy-MM-dd HH:mm:ss", new Date()),
          "dd/MM/yyyy HH:mm"
        );

      return theDate;
    },
    formatMultiDates(date) {
      if (date != undefined) {
        if (typeof date == "object") {
          return format(date._d, "dd/MM/yyyy");
        }

        if (date.length == 8) {
          let theDate = parse(date, "yyyyMMdd", new Date());
          return format(theDate, "dd/MM/yyyy");
        }

        if (date.length == 14) {
          let theDate = parse(date, "yyyyMMdd HH:mm", new Date());
          return format(theDate, "yyyy/MM/dd HH:mm");
        }

        if (date.length == 10) {
          let theDate = date;

          if (date.includes("-")) {
            theDate = parse(date, "yyyy-MM-dd", new Date());
            theDate = format(theDate, "dd/MM/yyyy");
          }

          return theDate;
        }

        if (
          date.includes("Horário") ||
          date.includes("Hora padrão") ||
          date.includes("GMT-0300")
        ) {
          return format(new Date(date), "dd/MM/yyyy");
        }

        if (date.length == 24) {
          let theDate = parse(date.split("T")[0], "yyyy-MM-dd", new Date());
          return format(theDate, "dd/MM/yyyy");
        }
      }
    },
  },
};
