export default {
  data() {
    return {
      creditLetterTransaction: {
        loading: false,
        details: {},
        list: [],
        meta: {},
        filters: {
          id: "",
          contractId: "",
          operationId: "",
          users: {
            list: [],
            selected: [],
          },
          operation: {
            list: [
              {
                label: "Emissão",
                value: "subtract",
              },
              {
                label: "Cancelado",
                value: "canceled",
              },
            ],
            selected: [],
          },
          period: {
            selected: [],
          },
          price: {
            min: "",
            max: "",
          },
          order: "desc",
          orderBy: "created",
        },
        pagination: {
          page: 1,
          perPage: 20,
          total: 0,
          complete_list_total: 0,
          totalPages: 0,
        },
      },
      creditLetterTransactionTableColumns: [
        {
          title: "ID OPERAÇÃO",
          dataIndex: "credit_letter_transaction_id",
          key: "credit_letter_transaction_id",
          class: "credit_letter_transaction_id",
          width: 80,
          sorter: true,
        },
        {
          title: "Valor ",
          key: "value",
          width: 80,
          sorter: true,
          scopedSlots: { customRender: "value" },
        },
        {
          title: "Operação",
          key: "operation",
          width: 120,
          sorter: true,
          scopedSlots: { customRender: "operation" },
        },
        {
          title: "ID Contrato",
          key: "contract_id",
          width: 100,
          sorter: true,
          scopedSlots: { customRender: "contract_id" },
        },
        {
          title: "ID Voyage",
          key: "voyage_id",
          dataIndex: "voyage_id",
          width: 100,
          sorter: true,
        },
        {
          title: "Usuário",
          width: 60,
          scopedSlots: { customRender: "user" },
        },
        {
          title: "Data/Hora",
          width: 100,
          scopedSlots: { customRender: "created" },
        },
        {
          title: "",
          key: "action",
          align: "right",
          width: 60,
          fixed: "right",
          scopedSlots: { customRender: "action" },
        },
      ],
    };
  },
  methods: {
    changeCreditLetterTransactionPage(current) {
      this.creditLetterTransaction.pagination.page = current;
      this.getCreditLetterTransactions();
    },
    creditLetterTransactionTableChange(pagination, filters, sorter) {
      filters;
      pagination;
      console.log(filters, pagination);
      this.creditLetterTransaction.filters.order =
        sorter.order != undefined ? sorter.order : "desc";
      this.creditLetterTransaction.filters.orderBy =
        sorter.column != undefined ? sorter.column.key : "created";
      this.getCreditLetterTransactions();
    },
    creditLetterTransactionChangePageSize(current, pageSize) {
      this.creditLetterTransaction.pagination.page = current;
      this.creditLetterTransaction.pagination.perPage = pageSize;
      this.getCreditLetterTransactions();
    },
    creditLetterTransactionFilters() {
      let filters = "";

      if (!this.$root.isAdmin()) {
        filters += `&user_id=${this.$store.state.userData.id}`;
      } else {
        if (this.creditLetterTransaction.filters.users.selected.length > 0) {
          filters += `&user_id=${this.creditLetterTransaction.filters.users.selected}`;
        }
      }

      if (this.creditLetterTransaction.filters.id) {
        filters += `&credit_letter_id=${this.creditLetterTransaction.filters.id}`;
      }

      if (this.creditLetterTransaction.filters.contractId) {
        filters += `&contract_id=${this.creditLetterTransaction.filters.contractId}`;
      }

      if (this.creditLetterTransaction.filters.operationId) {
        filters += `&credit_letter_transaction_id=${this.creditLetterTransaction.filters.operationId}`;
      }

      if (this.creditLetterTransaction.filters.operation.selected.length > 0) {
        filters += `&operation=${this.creditLetterTransaction.filters.operation.selected}`;
      }

      if (this.creditLetterTransaction.filters.period.selected.length > 0) {
        filters += `&period=${this.creditLetterTransaction.filters.period.selected[0]}|${this.creditLetterTransaction.filters.period.selected[1]}`;
      }

      if (
        this.creditLetterTransaction.filters.price.min ||
        this.creditLetterTransaction.filters.price.max
      ) {
        let min = this.creditLetterTransaction.filters.price.min;
        let max = this.creditLetterTransaction.filters.price.max;

        if (min) {
          if (!max) {
            max = "500000";
          }
        }

        if (max) {
          if (!min) {
            min = "0";
          }
        }

        filters += `&price-range=${min}-${max}`;
      }

      filters += `&order=${this.creditLetterTransaction.filters.order}&order-by=${this.creditLetterTransaction.filters.orderBy}`;

      return filters;
    },
    async createCreditCardTransaction(payload) {
      try {
        const data = await this.$http.post(
          "/credit-letter-transaction/create",
          payload
        );
        return data;
      } catch (e) {
        e;
      }
    },
    async getCreditLetterTransactionById(id) {
      try {
        const data = await this.$http.get(`/credit-letter/details?id=${id}`);
        return data;
      } catch (error) {
        console.log(error);
        this.$message.error("Houve algum erro ao carregar os dados.");
        this.creditLetterTransaction.details = {};
      }
    },
    async getCreditLetterTransactions(samePage) {
      this.creditLetterTransaction.loading = true;
      try {
        const { data } = await this.$http.get(
          `/credit-letter-transaction/list?page=${
            this.creditLetterTransaction.pagination.page
          }&per_page=${
            this.creditLetterTransaction.pagination.perPage
          }${this.creditLetterTransactionFilters()}`
        );

        this.creditLetterTransaction.list = data.data;
        this.creditLetterTransaction.pagination.page = data.meta.next_page;
        this.creditLetterTransaction.pagination.total = data.meta.total;

        if (!samePage) {
          this.creditLetterTransaction.pagination.page = 1;
        }
      } catch (e) {
        this.creditLetterTransaction.list = [];
        this.creditLetterTransaction.pagination.page = 0;
        this.creditLetterTransaction.pagination.total = 0;
        e;
      } finally {
        this.creditLetterTransaction.loading = false;
      }
    },
  },
};
