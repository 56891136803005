<template>
  <div>
    <div class="mt-10 relative">
      <a-form-item class="travel-input-outer">
        <label class="filled">Valor a ser retirado (R$)</label>
        <a-input
          class="travel-input"
          placeholder="'Digite o valor"
          v-currency="{
            currency: {
              prefix: '',
              suffix: '',
            },
            distractionFree: false,
            valueAsInteger: false,
            precision: 2,
            autoDecimalMode: true,
            allowNegative: false,
          }"
          v-model="creditLetterEmission.value"
        >
          <template slot="prefix"> R$ </template>
        </a-input>
      </a-form-item>
    </div>

    <div class="mt-10 relative">
      <a-form-item class="travel-input-outer">
        <label class="filled">ID Voyage (opcional)</label>
        <a-input
          class="travel-input"
          placeholder="'Digite"
          v-model="creditLetterEmission.idVoyage"
        >
        </a-input>
      </a-form-item>
    </div>

    <div class="mt-10 relative">
      <a-form-item class="travel-input-outer">
        <label class="filled">ID Contrato (opcional)</label>
        <a-input
          class="travel-input"
          placeholder="'Digite"
          v-model="creditLetterEmission.contractId"
        >
        </a-input>
      </a-form-item>
    </div>

    <div class="mt-30 a-center">
      <a-popconfirm
        :title="
          'Tem certeza que deseja retirar R$ ' +
          creditLetterEmission.value +
          '?'
        "
        ok-text="Sim"
        cancel-text="Não"
        :disabled="creditLetterEmission.value != undefined ? false : true"
        @confirm="confirmCreditLetterEmission"
      >
        <a-button
          :loading="creditLetterEmission.loading"
          :disabled="creditLetterEmission.value != undefined ? false : true"
          type="primary"
        >
          CONFIRMAR RETIRADA
        </a-button>
      </a-popconfirm>
    </div>
  </div>
</template>

<script>
import formatThings from "@/mixins/general/formatThings";
export default {
  name: "CreditLetterManualEmission",
  props: {
    creditLetter: Object,
  },
  mixins: [formatThings],
  data() {
    return {
      creditLetterEmission: {
        loading: false,
        value: undefined,
        idVoyage: undefined,
        contractId: undefined,
      },
    };
  },
  mounted() {},
  methods: {
    confirmCreditLetterEmission() {
      if (this.creditLetterEmission.value) {
        if (
          parseFloat(
            this.convertNumberToDatabase(this.creditLetterEmission.value)
          ) <= parseFloat(this.creditLetter.details.available_value)
        ) {
          this.creditLetterEmission.loading = true;
          this.$http
            .post("/credit-letter-transaction/create", {
              contract_id: this.creditLetterEmission.contractId,
              voyage_id: this.creditLetterEmission.idVoyage,
              customer_id: 0,
              is_canceled: 0,
              credit_letter_code: this.creditLetter.details.code,
              user_id: this.$store.state.userData.id,
              currency: "brl",
              value: this.convertNumberToDatabase(
                this.creditLetterEmission.value
              ),
              operation: "subtract",
              extra_info: "",
              credit_letter_id: this.creditLetter.details.id,
            })
            .then(({ data }) => {
              this.$emit("onCreateTransaction");
              this.creditLetterEmission.loading = false;
              this.creditLetterEmission.value = undefined;
              this.$message.success(data.message);
            })
            .catch(({ response }) => {
              this.creditLetterEmission.value = undefined;
              this.creditLetterEmission.loading = false;
              this.$message.error(response.data.message);
            });
        } else {
          this.$message.warning(
            "O valor a ser retirado não pode ser maior do que o disponível."
          );
        }
      } else {
        this.$message.warning("Insira o valor a ser retirado.");
      }
    },
  },
};
</script>
